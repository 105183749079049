<template>
  <div class="integral">
    <!-- 顶部导航栏 -->
    <topNav></topNav>

    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>专注积分商城用户运营</div>
            <div>
              深度迎合企业需求，量身打造积分生态体系
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 中间部分开始 -->
    <div class="integralAll">
      <div class="all-operate">
        <p class="operate-title">积分商城一站式运营</p>
        <div class="operate-content">
          <ul>
            <li
              class="operate-item"
              v-for="(v, i) in List.operateList"
              :key="i"
              @mouseenter="enter(i)"
              @mouseleave="leave()"
            >
              <div
                class="item-bg"
                :style="{
                  background: 'url(' + v.bg + ') left top no-repeat',
                }"
              ></div>
              <div
                class="item-bg2"
                :class="{ 'item-bg2move': v.id == List.operateNum }"
                :style="{
                  background: 'url(' + v.bg2 + ') top left no-repeat',
                }"
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="intergra-baaner">
        <div class="banner-content">
          <div class="content-bg"></div>
        </div>
      </div>
      <div class="integral-obj">
        <p class="obj-title">2000+企业的共同选择</p>
        <div class="obj-box">
          <ul>
            <li
              :class="{ active: v.id == List.IntegralNum }"
              @click="tabIntegral(i)"
              v-for="(v, i) in List.objList"
              :key="i"
            >
              {{ v.title }}
            </li>
          </ul>
          <div
            class="obj-detail"
            :class="{ opObj: v.id == List.IntegralNum }"
            v-show="v.id == List.IntegralNum"
            v-for="(v, i) in List.objList"
            :key="i"
          >
            <div class="detail-img">
              <img :src="v.appImg" alt="" />
            </div>
            <div class="detail-font">
              <p class="p1">{{ v.p1 }}</p>
              <p class="p2">{{ v.p2 }}</p>
              <p class="p3">
                {{ v.p3 }}
              </p>
              <a class="more">了解更多 ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="integral-bottom"></div>
    </div>
    <!-- 中间部分结束 -->

    <!-- 中间移动部分开始 -->
    <div class="phoneAll">
      <div class="section-1">
        <h2>优越积分</h2>
        <p>
          <span>
            优越积分，专注于解决企业与用户之间的积分兑换权益的需求，已服务于保险、金融、银行、通信、互联网等10余个行业，提供定制积分权益解决方案，实现流量快速变现，让积分发挥更大价值。
          </span>
        </p>
      </div>
      <div class="section-2">
        <h2>优越积分完美助力 丨 赋能品牌更精彩</h2>
        <div class="phone-content">
          <ul>
            <li class="content-one" v-for="(v, i) in List.phoneList" :key="i">
              <div class="title">
                <img :src="v.img" alt="" />
                <span class="titile-msg">{{ v.title }}</span>
              </div>
              <div class="text">
                {{ v.p1 }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section-3">
        <h2>深度分析企业需求，定制服务更有价值</h2>
        <ul class="demand">
          <li class="demand-li" v-for="(v, i) in List.demandList" :key="i">
            <div class="demand-item">
              <img :src="v.img" alt="" />
              <div class="item-right">
                <p class="title">{{ v.title }}</p>
                <p>{{ v.p1 }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 中间移动部分结束 -->
    <Bottom></Bottom>
  </div>
</template>

<script scoped>
import "../assets/less/integral.less";
import alllist from "../assets/js/index";
import List from "../assets/js/integral";
import topNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {
      // *全部列表
      alllist,
      List,
    };
  },
  components: {
    topNav,
    Bottom,
  },
  // 刷新一次
  mounted: function() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: {
    // 鼠标移入
    enter(index) {
      if (index + 1 == this.List.operateNum) {
        return;
      }
      this.List.operateNum = index + 1;
    },
    leave() {
      this.List.operateNum = 0;
    },
    //   切换
    tabIntegral(index) {
      if (index + 1 == this.List.IntegralNum) {
        return;
      }
      this.List.IntegralNum = index + 1;
    },
  },
};
</script>

<style lang="less" scoped></style>
